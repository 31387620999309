import React from "react";
import PropTypes from "prop-types";
import InputField from "../common/InputField";
import { Trans } from "react-i18next";
import { useFlags } from "launchdarkly-react-client-sdk";
import PrimaryEmailInputField from "./PrimaryEmailInputField";
import { connect } from "react-redux";

const ContactInformation = ({ givenName, familyName, primaryEmail }) => {
  const flags = useFlags();

  return (
    <div className="profile-section">
      <h1>
        <Trans i18nKey="profile-tab.account-contact-information" />
      </h1>
      <p className="hint">
        <Trans i18nKey="profile-tab.contact-admin" />
      </p>
      <InputField
        label={<Trans i18nKey="common.first-name" />}
        htmlId="first-name"
        initialValue={givenName}
        readonly={true}
      />
      <InputField
        label={<Trans i18nKey="common.last-name" />}
        htmlId="last-name"
        initialValue={familyName}
        readonly={true}
      />
      {flags["dev.show-contact-email"] === true && (
        <PrimaryEmailInputField primaryEmail={primaryEmail} />
      )}
    </div>
  );
};

ContactInformation.propTypes = {
  familyName: PropTypes.string,
  givenName: PropTypes.string,
  primaryEmail: PropTypes.string
};

function mapStateToProps(state) {
  return {
    user: state.userReducer.user
  };
}

const connectedContactInformation =
  connect(mapStateToProps)(ContactInformation);

export default connectedContactInformation;
